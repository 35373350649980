import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import SeperatorIcon from '../../../images/homepage/seperator.jpg'
import Logo from '../../../images/homepage/footer/logo_frs.png'
import FrisslyImg from '../../../images/homepage/footer/frissly_text.png'

const FooterContainer = styled(Box)({
  backgroundColor: "#FFFFFF",
  padding: "40px 0 40px",
  textAlign: "center",
});

const FooterTitle = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '38px',
  fontWeight: 700,
  lineHeight: '35px',
  color: '#3D3D3D',
  paddingBottom: "10px",

  '@media(max-width: 767px)': {
    fontSize: '18px',
    lineHeight: '24px',
  }
});

const Separator = styled('img')`
  display: block;
  margin: 0 auto 20px; 
  padding-bottom: 20px;

  @media(max-width: 767px){
    margin: 0 auto 15px;
    width: 32%;
  }
`;

const FooterText = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "21px",
  fontWeight: "600",
  lineHeight: "35px",
  textAlign: "center",
  color: "#014C35",

  '@media(max-width: 767px)': {
    fontSize: "20px",
    lineHeight: "27px",
  }
});

const FooterLink = styled(Link)({
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "21.94px",
  color: "#014C35",
  textDecoration: "underline",
  margin: "0 20px",

  '@media(max-width: 767px)': {
    fontSize: "12px",
    lineHeight: "16px",
  }

});

const SocialIconButton = styled(IconButton)({
  color: "#014C35",
  margin: "0 10px",
});

const IconImg = styled('img')`
  width: 50px;
  height: 50px;
  object-fit: contain;
  display: block;
`;

const IconImg1 = styled('img')`
  width: 70px;
  height: 70px;
  object-fit: contain;
  display: block;
`;



const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <FooterTitle>
              {/* Talk to Us */}
              Contact Us
            </FooterTitle>
            <Separator src={SeperatorIcon} alt="Separator" />
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', py: '15px'}}>
              <IconImg src={Logo} />
              <IconImg1 src={FrisslyImg} />
            </Box>
          </Grid>

          <Grid item>
            <SocialIconButton href="#" target="_blank">
              <InstagramIcon />
            </SocialIconButton>
            <SocialIconButton href="#" target="_blank">
              <LinkedInIcon />
            </SocialIconButton>
            <SocialIconButton href="#" target="_blank">
              <TwitterIcon />
            </SocialIconButton>
            <SocialIconButton href="#" target="_blank">
              <FacebookIcon />
            </SocialIconButton>
            <SocialIconButton href="#" target="_blank">
              <GoogleIcon />
            </SocialIconButton>
          </Grid>
          <Grid item xs={12} sx={{paddingTop: '4px !important'}}>
            <FooterText>
            contact@frissly.in
            </FooterText>
          </Grid>
          <Grid item xs={12} sx={{paddingTop: '4px !important'}}>
            <FooterText>
            +91 9966097097
            </FooterText>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", gap: "30px", paddingBottom: {xs:'70px !important', sm: '120px !important'}, paddingTop: '40px !important', marginBottom: '50px'  }}
          >
            <FooterLink href="#terms">Terms & Conditions</FooterLink>
            <FooterLink href="#privacy">Privacy Policy</FooterLink>
          </Grid>
        </Grid>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
