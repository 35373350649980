import React, { useState } from "react";
import { Box, Grid, Typography, Container, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import WomenImg from "../../../images/homepage/women.jpg";
import GlassImg from "../../../images/homepage/glasses.webp";
import HeroImg from "../../../images/homepage/heroImg.png";
import Header from "../../Header/PrelaunchHeader/Header";
import SweetLaddoImg from "../../../images/homepage/banner/sweet_laddo.png";
import HeroBgImg2 from "../../../images/homepage/banner/heroImg2.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import 'swiper/css/navigation';
import 'swiper/css';
import './HeroSection.css';



const ParentBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  height: '80vh',

  '@media (max-width: 767px)': {
    height: '28vh',
  }
});

const StyledBox = styled(Box)({
  position: 'relative',
});

const StyledImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain",
});

const Overlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.3)",
});

const ContentBox = styled(Box)({
  padding: "40px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  
  '@media (max-width: 767px)': {
    padding: "20px",
  }

});

const Title = styled(Typography)({
  fontFamily: 'Gloria Hallelujah',
  fontSize: '62px',
  fontWeight: 400,
  lineHeight: '90px',
  color: '#3D3D3D',
  paddingBottom: '20px',
  
  '@media (max-width: 767px)': {
    fontSize: '24px',
    lineHeight: '43px',
    color: '#000000',
    textAlign: 'center',
  }
  
});

const Subtitle = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30.01px',
  color: '#000000',
  textAlign: 'left',
  
  '@media (max-width: 767px)': {
    fontSize: '14px',
    lineHeight: '19px',
    padding: '0 20px',
    textAlign: 'center',
  }

});

const slides = [
  {
    image: HeroImg,
    bgImg: "#F0FFFA",
    title: "Welcome to Frissly",
    subtitle: "Your Destination for Pure, Organic, Wholesome, and Sustainable Foods",
  },
  {
    image: SweetLaddoImg,
    bgImg: `url(${HeroBgImg2})`,
    title: "Welcome to Frissly",
    subtitle: "Your Destination for Pure, Organic, Wholesome, and Sustainable Foods",
  }
]

const ImageSection = () => {
  const [buttonColor, setButtonColor] = useState('button-custom');
  const isMobile = useMediaQuery('(max-width: 767px)');
  
  const handleSlideChange = () => {
    setButtonColor((prevClass) => 
      prevClass === "button-custom" 
      ? "button-custom button-custom-active" 
      : "button-custom"
    )
  }

  return (
        <Swiper
          spaceBetween={15}
          slidesPerView={1}
          loop={true}
          navigation={true}
          autoplay={{delay: 5000, disableOnInteraction: false}}
          modules={[Autoplay, Navigation, Pagination]}
          className="mySwiper"
          onSlideChange= {handleSlideChange}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <Box sx={{ background: slide?.bgImg, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', paddingBottom: {
                xs: "30px", sm: "unset"
              } }}>
              <Container>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <ParentBox>
                    <StyledBox>
                      {/* <StyledImage src={HeroImg} alt="Hero" /> */}
                      <StyledImage src={slide?.image} alt="Hero" />
                      {/* <Overlay>
                <Header />
              </Overlay> */}
                    </StyledBox>
                  </ParentBox>
                </Grid>
                <Grid item xs={6} md={6} container alignItems="center">
                  <ContentBox>
                    {/* <Title>Welcome to Frissly</Title>
            <Subtitle>Your Destination for Pure, Organic, Wholesome, and Sustainable Foods</Subtitle> */}
                    <Title>{slide?.title}</Title>
                    {!isMobile && (<Subtitle>{slide?.subtitle}</Subtitle>)}
                  </ContentBox>
                </Grid>
              </Grid>
              {isMobile && (<Subtitle>{slide?.subtitle}</Subtitle>)}
              </Container>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
  );
};

export default ImageSection;
