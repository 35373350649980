import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import LeafImg from '../../../images/homepage/launching_soon/Leaves.png';
import FirstImg from '../../../images/homepage/launching_soon/dosa.png';
import SecondImg from '../../../images/homepage/launching_soon/dish.png';
import SeparatorIcon from '../../../images/homepage/launching_soon/seperator1.png';
import AppleImg from '../../../images/homepage/launching_soon/exportImage_apple.png';
import GooglePlayImg from '../../../images/homepage/launching_soon/exportImage_google.png';


const FixedBox = styled(Box)({
  position: 'fixed',
  bottom: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  background: 'var(--Color-100, #014C35)',
  padding: '14px 0',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  zIndex: 1000,
  width: '100%',
});

const StyledText1 = styled(Typography)({
  fontFamily: 'Gloria Hallelujah',
  fontSize: '17px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#FFFFFF',
  textAlign: 'center',
  paddingBottom: '8px',
});

const StyledText2 = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '28px',
  fontWeight: 700,
  lineHeight: '24px',
  color: '#FFFFFF',
  textAlign: 'center',
  paddingBottom: '10px',
});

const Image = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  margin: '0 10px',
  border: "1.5px solid white",
  borderRadius: "8px",
});

const LeafImage = styled('img')({
  position: 'absolute',
  top: '-15px', 
  right: '0px', 
  width: '80px',
  height: 'auto',
  zIndex: -99,

  '@media (min-width: 768px) and (max-width: 991px)': {
    right: '-30px'
  },
  '@media (min-width: 991px) and (max-width: 1024px)': {
    right: '-15px'
  }
});


const Separator = styled('img')({
  display: 'block',
  margin: '0 auto',
  width: '100%',
  height: '18px',
  objectFit: 'contain'
});

const TopImageContainer = styled(Grid)({
  marginTop: '-30px',
});

const BottomImageContainer = styled(Grid)({
  marginBottom: '-30px',
});

const LeafImageContainer = styled(Box)({
  position: 'absolute',
  top: '20%',
  right: '-90px',
  transform: 'translateY(-50%)',
});

const TextContainer = styled(Box)({
  position: 'relative',
  display: 'inline-block',
});

const LaunchingSoon = () => {
  return (
    <FixedBox>
      <Container>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={3} container spacing={2} justifyContent="center" alignItems="center">
            <TopImageContainer item xs={4}>
              <Image src={FirstImg} sx={{width: "80%", height: "80%",}} alt="First Image" />
            </TopImageContainer>
            <BottomImageContainer item xs={4}>
              <Image src={SecondImg} sx={{width: "80%", height: "80%",}} alt="Second Image" />
            </BottomImageContainer>
          </Grid>
          <Grid item xs={12} sm={6} container direction="column" alignItems="center">
            <StyledText1>The wait is over</StyledText1>
            <TextContainer>
              <StyledText2>Launching Soon</StyledText2>
              {/* <LeafImageContainer>
              <Image src={LeafImg} alt="Leaf" sx={{ width: '90px', height: '70px', border: "unset" }} />
              </LeafImageContainer> */}
            </TextContainer>
            <Separator src={SeparatorIcon} alt="Separator" />
          </Grid>
          <Grid item xs={12} sm={3} container spacing={2}>
            <Grid item xs={12} sx={{paddingLeft: '6px!important'}}>
              <Image sx={{height: '38px', border: "unset",}} src={AppleImg} alt="Apple Store" />
            </Grid>
            <Grid item xs={12} sx={{position: 'relative', paddingTop: '10px!important', paddingLeft: '6px!important'}}>
              <Image sx={{height: '38px', border: "unset",}} src={GooglePlayImg} alt="Google Play" />
              <LeafImage src={LeafImg} alt="Leaf" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </FixedBox>
  );
};

export default LaunchingSoon;
