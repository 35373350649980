import React, { useState } from "react";
import { Box, Button, ButtonGroup, styled, Typography } from "@mui/material";

const ToggleButton = styled(Button)(({ selected }) => ({
  backgroundColor: selected ? "#F0FFFA" : "var(--Color-90, #0B6E4F)",
  color: selected ? "#000000" : "#6BF6CB",
  fontSize: selected ? "12px" : "11px",
  fontWeight: selected ? 500 : 400,
  padding: "8px 10px",
  borderRadius: "8px",
  borderBottomRightRadius: "8px!important",
  borderTopRightRadius: "8px!important",
  borderBottomLeftRadius: "8px!important",
  borderTopLeftRadius: "8px!important",
  border: "none",
  width: "50%",
  "&:hover": {
    backgroundColor: selected ? "#F0FFFA" : "var(--Color-90, #0B6E4F)",
  },

  "@media (max-width: 360px)": {
    padding: "8px",
  },
  "@media (max-width: 344px)": {
    padding: "8px 5px",
  },
}));

const ToggleButtonContainer = styled(Box)({
  backgroundColor: "var(--Color-90, #0B6E4F)",
  padding: "4px",
  borderRadius: "8px",
  marginBottom: "15px",
});

const ToggleButtons = ({ selected, setSelected }) => (
  <ToggleButtonContainer>
    <ButtonGroup sx={{ display: "flex", justifyContent: "space-between" }}>
      <ToggleButton
        disableRipple
        disableFocusRipple
        selected={selected === "use"}
        onClick={() => setSelected("use")}
      >
        Ingredients We Use
      </ToggleButton>
      <ToggleButton
        disableRipple
        disableFocusRipple
        selected={selected === "avoid"}
        onClick={() => setSelected("avoid")}
      >
        Ingredients We Avoid
      </ToggleButton>
    </ButtonGroup>
  </ToggleButtonContainer>
);

export default ToggleButtons;
