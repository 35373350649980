import React, { useState } from "react";
import { Box, Typography, Grid, styled } from "@mui/material";
import ToggleButtons from "./ToggleButtons";

import SeperatorIcon from "../../../images/homepage/seperator.jpg";
import JagauryImg from "../../../images/homepage/our_ingredients/jaguary.png";
import WalnutImg from "../../../images/homepage/our_ingredients/nuts-walnut.png";
import FoodssImg from "../../../images/homepage/our_ingredients/foodss.png";
import GroupFood from "../../../images/homepage/our_ingredients/groupfood.png";
import NoGMOimg from "../../../images/homepage/our_ingredients/no_gmo.png";
import BlackPepperImg from "../../../images/homepage/our_ingredients/Black_pepper.png";
import ChemicalTestImg from "../../../images/homepage/our_ingredients/chemical_test_tube.png";
import MaskGroup from "../../../images/homepage/our_ingredients/Mask_group.png";
import FileImg from "../../../images/homepage/our_ingredients/file9.png";

const Container = styled(Box)({
  textAlign: "center",
  padding: "1rem",
});

const SectionTitle = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: "28px",
  fontWeight: 700,
  lineHeight: "35px",
  color: "#3D3D3D",
  paddingBottom: "10px",

  '@media (max-width: 767px)': {
    fontSize: '20px',
    lineHeight: '24.55px',
  }
});

const ItemTitle = styled(Typography)(({align}) => ({
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "21.82px",
  letterSpacing: "0.03em",
  color: "#000000",
  marginBottom: "10px",
  textAlign: align,
}));

const ItemDescription = styled(Typography)(({align}) => ({
  fontFamily: "Nunito",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#666666",
  margin: "0.5rem 0",
  textAlign: align,
}));

const CircularImage = styled("img")({
  width: "80px",
  height: "80px",
  borderRadius: "50%",
  objectFit: "contain",
});

const ItemContainer = styled(Box)({
  padding: "1rem 0",
});

const Separator = styled("img")`
  display: block;
  margin: 0 auto 20px;
  padding-bottom: 30px;

  @media (max-width: 767px) {
    padding-bottom: 20px;
    width: 32%;
  }
`;

const TransparencyText = styled(Typography)({
  fontFamily: "Gloria Hallelujah",
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "35px",
  color: "var(--Color-100, #014C35)",
  textAlign: "center",
  paddingBottom: "8px",

  '@media (max-width: 767px)': {
    fontSize: '16px',
    color: '#0B6E4F',
    lineHeight: '19px',
  }
});

const items = {
    use: [
      {
        img: JagauryImg,
        title: "Natural Sweeteners",
        description: "Utilizing Honey, Jaggery, Coconut Sugar, Brown Sugar, Palm Sugar and other natural sweeteners to enhance taste while promoting health.",
        align: "left"
      },
      {
        img: WalnutImg,
        title: "100% Organic Certified and Non-GMO",
        description: "We are using only organically certified and non-genetically modified ingredients.",
        align: "left"
      },
      {
        img: FoodssImg,
        title: "Organic certified Milk, Ghee and Butter",
        description: "Using high-quality, Organic certified Cow based dairy products to ensure rich flavor and nutritional value.",
        align: "left"
      },
      {
        img: GroupFood,
        title: "Whole Grains & Cold Pressed Oils",
        description: "Featuring whole grains such as Millets, Red rice, Oats, Barley and whole wheat, and using Cold pressed oils for their superior nutritional benefits.",
        align: "left",
      }
    ],
    avoid: [
        {
            img: FileImg,
            title: "Refined",
            description: "Avoiding refined flours, sugars, Oils to support healthier lifestyles and prevent chronic health conditions.",
            align: "right"
          },
      {
        img: ChemicalTestImg,
        title: "Artificial Preservatives",
        description: "Keeping our products free from artificial preservatives to maintain their natural goodness.",
        align: "right"
      },
      {
        img: BlackPepperImg,
        title: "Synthetic Additives & flavors",
        description: "Steering clear of synthetic colors, flavors, and additives to provide clean, wholesome food.",
        align: "right"
      },
      {
        img: NoGMOimg,
        title: "Genetically Modified Organisms (GMOs)",
        description: "Rejecting GMOs to ensure that our food remains as natural and unaltered as possible.",
        align: "right"
      },
    ]
  };
  

const IngredientsMobile = () => {
  const [selected, setSelected] = useState("use");

  return (
    <Container>
      <TransparencyText>
        Transparency and Sustainability at Every Step
      </TransparencyText>
      <SectionTitle>Our Ingredients</SectionTitle>
      <Separator src={SeperatorIcon} alt="Separator" />

      <ToggleButtons selected={selected} setSelected={setSelected} />
      
      <Box>
        {items[selected].map((item, index) => (
          <ItemContainer key={index}>
            <Grid container alignItems="center" spacing={2}>
              {item.align === "left" ? (
                <>
                  <Grid item xs={4}>
                    <CircularImage src={item.img} alt={item.title} />
                  </Grid>
                  <Grid item xs={8}>
                    <ItemTitle align={item.align}>{item.title}</ItemTitle>
                    <ItemDescription align={item.align}>{item.description}</ItemDescription>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={8}>
                    <ItemTitle align={item.align}>{item.title}</ItemTitle>
                    <ItemDescription align={item.align}>{item.description}</ItemDescription>
                  </Grid>
                  <Grid item xs={4}>
                    <CircularImage src={item.img} alt={item.title} />
                  </Grid>
                </>
              )}
            </Grid>
          </ItemContainer>
        ))}
      </Box>

    </Container>
  );
};

export default IngredientsMobile;
