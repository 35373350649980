import React, { forwardRef } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";
import WomenImg from "../../../images/homepage/women.jpg";
import SeperatorIcon from "../../../images/homepage/seperator.jpg";
import JagauryImg from "../../../images/homepage/our_ingredients/jaguary.png";
import WalnutImg from "../../../images/homepage/our_ingredients/nuts-walnut.png";
import FoodssImg from "../../../images/homepage/our_ingredients/foodss.png";
import GroupFood from "../../../images/homepage/our_ingredients/groupfood.png";
import NoGMOimg from "../../../images/homepage/our_ingredients/no_gmo.png";
import BlackPepperImg from "../../../images/homepage/our_ingredients/Black_pepper.png";
import ChemicalTestImg from "../../../images/homepage/our_ingredients/chemical_test_tube.png";
import MaskGroup from "../../../images/homepage/our_ingredients/Mask_group.png";
import FileImg from '../../../images/homepage/our_ingredients/file9.png';

const Container = styled(Box)({
  textAlign: "center",
  padding: "1rem",
});

const SectionTitle = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: "38px",
  fontWeight: 700,
  lineHeight: "35px",
  color: "#3D3D3D",
  paddingBottom: "10px",
});

const Separator = styled("img")`
  display: block;
  margin: 0 auto 20px;
  padding-bottom: 30px;
`;

const SubTitle = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: "28px",
  fontWeight: 600,
  lineHeight: "38.19px",
  color: "#014C35",
  marginBottom: "1rem",
  textAlign: "right",

  '@media (min-width: 768px) and (max-width: 820px)': {
    fontSize: "25px",
  },
  '@media (min-width: 820px) and (max-width: 991px)': {
    fontSize: "25px",
    minHeight: "70px",
  }
});

const ItemTitle1 = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "21.82px",
  letterSpacing: "0.03em",
  color: "#000000",
  margin: "1rem 0",
  textAlign: "right",

  '@media (min-width: 768px) and (max-width: 991px)': {
    fontSize: "15px",
    minHeight: "30px",
    margin: "unset",
    marginBottom: "6px",
  }
});

const ItemTitle2 = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "21.82px",
  letterSpacing: "0.03em",
  color: "#000000",
  margin: "1rem 0",
  textAlign: "left",

  '@media (min-width: 768px) and (max-width: 991px)': {
    fontSize: "15px",
    minHeight: "30px",
    margin: "unset",
    marginBottom: "6px",
  }
});

const ItemDescription1 = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "26px",
  color: "#666666",
  margin: "0.5rem 0",
  textAlign: "right",
});

const ItemDescription2 = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "26px",
  color: "#666666",
  margin: "0.5rem 0",
  textAlign: "left",
});

const CircularImage = styled("img")(({customMar}) => ({
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  margin: "1rem 0",
  objectFit: "contain",

  '@media (min-width: 768px) and (max-width: 820px)': {
    width: "80px",
    height: "80px",
  },
  '@media (min-width: 820px) and (max-width: 991px)': {
    width: "80px",
    height: "80px",
    marginLeft: customMar,
  },
  '@media (min-width: 1024px) and (max-width: 1280px)': {
    width: "80px",
    height: "80px",
  },
  '@media (min-width: 1024px) and (max-width: 1025px)': {
    marginLeft: customMar,
  },
}));

const LargeImage = styled("img")({
  width: "300px",
  height: "300px",
  borderRadius: "50%",
  margin: "2rem 0",
  objectFit: "contain",

  '@media (min-width: 768px) and (max-width: 991px)': {
    width: "160px",
    height: "160px",
    margin: "unset",
  },
  '@media (min-width: 1024px) and (max-width: 1280px)': {
    width: "200px",
    height: "200px",
    margin: "unset",
  }
});

const ItemContainer = styled(Box)({
  textAlign: "center",
  padding: "1rem",

  '@media (min-width: 768px) and (max-width: 913px)': {
    padding: "unset",
  }
});

const TransparencyText = styled(Typography)({
  fontFamily: "Gloria Hallelujah",
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "35px",
  color: "var(--Color-100, #014C35)",
  textAlign: "center",
  paddingBottom: "8px",
});

const Ingredients = forwardRef((props, ref) => {
  return (
    <Container>
      <Box ref={ref}>
      <TransparencyText>
        Transparency and Sustainability at Every Step
      </TransparencyText>
      <SectionTitle>Our Ingredients</SectionTitle>
      <Separator src={SeperatorIcon} alt="Separator" />
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <SubTitle sx={{ textAlign: "right", pr: 4 }}>
            Ingredients We Use
          </SubTitle>
          <ItemContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={9}>
                <ItemTitle1>Natural Sweeteners</ItemTitle1>
                <ItemDescription1>
                  Utilizing Honey,  Jaggery,  Coconut Sugar, Brown Sugar, Palm
                  Sugar  and other natural sweeteners to enhance taste while
                  promoting health.
                </ItemDescription1>
              </Grid>
              <Grid item xs={12} sm={4} md={3} container alignItems="center">
                <CircularImage src={JagauryImg} alt="egg" />
              </Grid>
            </Grid>
          </ItemContainer>
        </Grid>
        <Grid item xs={12} sm={1} md={2} lg={2}></Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <SubTitle sx={{ textAlign: "left", pl: 4 }}>
            Ingredients We Avoid
          </SubTitle>
          <ItemContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3} container alignItems="center">
                <CircularImage customMar={'-24px'} src={FileImg} alt="egg" />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <ItemTitle2>Refined</ItemTitle2>
                <ItemDescription2>
                  Avoiding refined flours, sugars, Oils to support healthier
                  lifestyles and prevent chronic health conditions.
                </ItemDescription2>
              </Grid>
            </Grid>
          </ItemContainer>
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <ItemContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={9} lg={9}>
                <ItemTitle1>100% Organic Certified and Non-GMO</ItemTitle1>
                <ItemDescription1>
                  We are using only organically certified and non-genetically
                  modified ingredients.
                </ItemDescription1>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} container alignItems="center">
                <CircularImage src={WalnutImg} alt="egg" />
              </Grid>
            </Grid>
          </ItemContainer>
          <ItemContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={9} lg={9}>
                <ItemTitle1>Organic certified Milk, Ghee and Butter</ItemTitle1>
                <ItemDescription1>
                  Using high-quality, Organic certified Cow based dairy products
                  to ensure rich flavor and nutritional value.
                </ItemDescription1>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} container alignItems="center">
                <CircularImage src={FoodssImg} alt="egg" />
              </Grid>
            </Grid>
          </ItemContainer>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={3}
          lg={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <LargeImage src={MaskGroup} alt="cookie" />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <ItemContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3} lg={3} container alignItems="center">
                <CircularImage customMar={'-24px'} src={ChemicalTestImg} alt="egg" />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={9}>
                <ItemTitle2>Artificial Preservatives</ItemTitle2>
                <ItemDescription2>
                  Keeping our products free from artificial preservatives to
                  maintain their natural goodness.
                </ItemDescription2>
              </Grid>
            </Grid>
          </ItemContainer>
          <ItemContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3} lg={3} container alignItems="center">
                <CircularImage src={BlackPepperImg} customMar={'-24px'} alt="egg" />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={9}>
                <ItemTitle2>Synthetic Additives & flavors</ItemTitle2>
                <ItemDescription2>
                  Steering clear of synthetic colors, flavors, and additives to
                  provide clean, wholesome food.
                </ItemDescription2>
              </Grid>
            </Grid>
          </ItemContainer>
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <ItemContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={9} lg={9}>
                <ItemTitle1>Whole Grains & Cold Pressed Oils</ItemTitle1>
                <ItemDescription1>
                  Featuring whole grains such as Millets, Red rice, Oats, Barley
                  and whole wheat, and using Cold pressed oils for their
                  superior nutritional benefits.
                </ItemDescription1>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} container alignItems="center">
                <CircularImage src={GroupFood} alt="egg" />
              </Grid>
            </Grid>
          </ItemContainer>
        </Grid>
        <Grid item xs={12} sm={1} md={2} lg={2}></Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <ItemContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3} lg={3} container alignItems="center">
                <CircularImage customMar={'-24px'} src={NoGMOimg} alt="egg" />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={9}>
                <ItemTitle2>Genetically Modified Organisms (GMOs)</ItemTitle2>
                <ItemDescription2>
                  Rejecting GMOs to ensure that our food remains as natural and
                  unaltered as possible.
                </ItemDescription2>
              </Grid>
            </Grid>
          </ItemContainer>
        </Grid>
      </Grid>
      </Box>
    </Container>
  );
});

export default Ingredients;
