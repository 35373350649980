import LaunchingSoon from "../components/Home/LaunchingSoon/LaunchingSoon";
import OurProcess from "../components/Home/OurProcess/OurProcess";
import Footer from "../components/Footer/PrelaunchFooter/Footer";
import Header from "../components/Header/PrelaunchHeader/Header";
import FoodInfo from "../components/Home/FoodInfo/FoodInfo";
import Ingredients from "../components/Home/Ingredients/Ingredients";
import Manufacture from "../components/Home/Manufacture/Manufacture";
import AboutUs from "../components/Home/AboutUs/AboutUs";
import Navbar from "../components/Navbar/Navbar";
import Categories from "../components/Home/Categories/Categories";
import HeroSection from "../components/Home/HeroSection/HeroSection";
import { useRef } from "react";
import SustainableGoals from "../components/Home/SustainableGoals/SustainableGoals";

const Homepage = () => {
  const aboutUsRef = useRef(null);
  const ourProcessRef = useRef(null);
  const manufactureRef = useRef(null);
  const ingredientsRef = useRef(null);

  return (
    <>
      <Navbar
        aboutUsRef={aboutUsRef}
        ourProcessRef={ourProcessRef}
        manufactureRef={manufactureRef}
        ingredientsRef={ingredientsRef}
      />
      {/* <Header /> */}
      <HeroSection />
      {/*<FrisslyGrid /> 
     <OurProcess /> */}
      <LaunchingSoon /> 
      <Categories />
      <AboutUs ref={aboutUsRef}/>
      <SustainableGoals />
      <FoodInfo />
      <OurProcess ref={ourProcessRef} />
      <Manufacture ref={manufactureRef} />
      <Ingredients ref={ingredientsRef} />
      <Footer />
    </>
  );
}

export default Homepage