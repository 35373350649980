import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Container, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import logo from '../../images/homepage/footer/frissly_text.png'
const Logo = styled('img')({
  width: '70px',
  height: '45px',
  objectFit: 'contain',
  marginRight: '20px',
});

const Navbar = ({aboutUsRef, ourProcessRef, manufactureRef, ingredientsRef}) => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({behaviour: 'smooth'});
  }

  return (
    <>
      {isMobile && (
        <Box
          sx={{ background: "var(--Color-90, #0B6E4F)", height: "8px" }}
        ></Box>
      )}

      <Container sx={{ py: "2px" }}>
        <AppBar position="static" color="transparent" elevation={0}>
          {isMobile ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Logo src={logo} />
            </Box>
          ) : (
            <Toolbar>
              <Box display="flex" alignItems="center">
                <Logo src={logo} />
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box display="flex" alignItems="center">
                <Button
                  sx={{
                    color: "#014C35",
                    textTransform: "none",
                    fontSize: "18px",
                    fontFamily: "Nunito",
                    fontWeight: "400",
                    lineHeight: "24.55px",
                    padding: "0 30px",
                  }}
                  onClick={() => handleScroll(aboutUsRef)}
                >
                  About Us
                </Button>
                <Button
                  sx={{
                    color: "#014C35",
                    textTransform: "none",
                    fontSize: "18px",
                    fontFamily: "Nunito",
                    fontWeight: "400",
                    lineHeight: "24.55px",
                    padding: "0 30px",
                  }}
                  onClick={() => handleScroll(ourProcessRef)}
                >
                  Our Process
                </Button>
                <Button
                  sx={{
                    color: "#014C35",
                    textTransform: "none",
                    fontSize: "18px",
                    fontFamily: "Nunito",
                    fontWeight: "400",
                    lineHeight: "24.55px",
                    padding: "0 30px",
                  }}
                  onClick={() => handleScroll(manufactureRef)}
                >
                  Manufacture
                </Button>
                <Button
                  sx={{
                    color: "#014C35",
                    textTransform: "none",
                    fontSize: "18px",
                    fontFamily: "Nunito",
                    fontWeight: "400",
                    lineHeight: "24.55px",
                    padding: "0 30px",
                  }}
                  onClick={() => handleScroll(ingredientsRef)}
                >
                  Ingredients
                </Button>
              </Box>
            </Toolbar>
          )}
        </AppBar>
      </Container>
    </>
  );
};

export default Navbar;
