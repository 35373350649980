import React, { forwardRef } from 'react';
import { Box, Grid, Typography,useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import OnlineShoppingIcon from '../../../images/homepage/manufacture/online_shopping.png';
import HerbalMedicineIcon from '../../../images/homepage/manufacture/herbal_medicine.png';
import FlourIcon from '../../../images/homepage/manufacture/flour.png';
import HandleCareIcon from '../../../images/homepage/manufacture/handle_care.png';
import ScooterIcon from '../../../images/homepage/manufacture/scooter.jpg';
import SeperatorIcon from '../../../images/homepage/seperator.jpg'

const SectionTitle = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '38px',
  fontWeight: 700,
  lineHeight: '35px',
  color: '#3D3D3D',
  paddingBottom: '10px',
  textAlign: 'center',

  '@media (max-width: 767px)': {
    fontSize: '20px',
    lineHeight: '24.55px',
  }
});

const Separator = styled('img')`
  display: block;
  margin: 0 auto 20px; 
  padding-bottom: 80px;

  @media(max-width: 767px){
    padding-bottom: 40px;
    width: 32%;
  }
`;


const BoxContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  @media (min-width: 768px) and (max-width: 1025px) {
    margin: 30px 0;
  }
`;

const BoxItem = styled(Grid)`
  width: 236px;
  height: 256px;
  padding: 20px;
  border-radius: 16px;
  background: #F0FFFA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background 0.3s, transform 0.3s;
  position: relative;
  &:hover {
    background: #014C35;
    & img {
      transform: scaleX(-1);
    }
    & h6, & p {
      color: #fff;
    }
  }

  @media (max-width: 767px) {
    margin: 20px 0;
  }
`;

const Icon = styled('img')`
  width: 100px;
  height: 100px;
  margin-bottom: -50px;
  position: absolute;
  top: -65px;
  transition: transform 0.3s;
`;

const Title = styled(Typography)`
  font-family: Nunito;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #3D3D3D;
  z-index: 1;
  padding: 10px 0;
  text-align: center;

  @media(max-width: 767px){
    font-size: 18px;
    width: 50%;
    line-height: 19px;
  }
`;

const Description = styled(Typography)`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  line-height: 24.55px;
  text-align: center;
  color: #525252;
  z-index: 1;

    @media(max-width: 767px){
    font-size: 17px;
  }
`;

const OrganicEcoText = styled(Typography)({
  fontFamily: 'Gloria Hallelujah',
  fontSize: '22px',
  fontWeight: 400,
  lineHeight: '35px',
  color: 'var(--Color-100, #014C35)',
  textAlign: 'center',
  paddingBottom: '8px',

  '@media (max-width: 767px)': {
    fontSize: '16px',
    color: '#0B6E4F',
    lineHeight: '19px',
  }
});

const Manufacture = forwardRef((props, ref) => {
  const isTabletAndDesktop = useMediaQuery('(min-width: 768px) and (max-width: 1025px)');

  const items = [
    {
      title: 'Order Placement',
      description: 'Customers place their orders through the Frissly mobile app or website.',
      icon: OnlineShoppingIcon,
    },
    {
      title: 'Preparing Ingredients',
      description: 'All required masala, spice mixes, nut butters, chocolates, flavors, and more are prepared in-house.',
      icon: HerbalMedicineIcon,
    },
    {
      title: 'Small Batch Manufacturing',
      description: 'Ingredients are then used in small batches within 16 to 24 hours to ensure quality and freshness.',
      icon: FlourIcon,
    },
    {
      title: 'Eco-Friendly Packaging',
      description: 'Products are packaged in recyclable, eco-friendly materials with complete transparency on ingredients.',
      icon: HandleCareIcon,
    },
    {
      title: '24-Hour Delivery Promise',
      description: 'Orders are dispatched and delivered within 24 hours, maintaining the utmost freshness and quality.',
      icon: ScooterIcon,
    },
  ];

  return (
    <Box sx={{padding: {
      xs: "30px 0 20px",
      sm: '60px 0',
    }}}
      ref={ref}
    >
      <OrganicEcoText>An Organic Eco System</OrganicEcoText>
      <SectionTitle>Manufacture</SectionTitle>
      <Separator src={SeperatorIcon} alt="Separator" />

      <Grid container spacing={2} justifyContent={isTabletAndDesktop ? "flex-start" : "center"}>
        {items.map((item, index) => (
          <BoxContainer item key={index} xs={12} sm={6} md={4} lg={2}>
            <BoxItem>
              <Icon src={item.icon} alt={item.title} />
              <Title>{item.title}</Title>
              <Description>{item.description}</Description>
            </BoxItem>
          </BoxContainer>
        ))}
      </Grid>
    </Box>
  );
});

export default Manufacture;
