import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Grid, Typography,useMediaQuery, theme } from '@mui/material';
import { styled } from '@mui/system';
import GreenImage from '../../../images/homepage/our_process/highlight.png';
import KitchenImage from '../../../images/homepage/our_process/kitchen.png';
import SeperatorIcon from '../../../images/homepage/seperator.jpg'
import ReactangleImg from '../../../images/homepage/about_us/rectangle.png';
import ReactangleImg1 from '../../../images/homepage/about_us/rectangle_1.png';


const SectionTitle = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '38px',
  fontWeight: 700,
  lineHeight: '35px',
  color: '#3D3D3D',
  paddingBottom: '10px',
  textAlign: 'center',

  '@media (max-width: 767px)': {
    fontSize: '20px',
    lineHeight: '24.55px',
  }
});

const Separator = styled('img')`
  display: block;
  margin: 0 auto 20px; 
  padding-bottom: 30px;

  @media(max-width: 767px){
    margin: 0 auto 15px;
    padding-bottom: unset;
  }
`;

const Heading = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '28px',
  fontWeight: 700,
  lineHeight: '35px',
  textAlign: 'left',
  color: '#3D3D3D',
  paddingTop: '30px',

  '@media (max-width: 767px)': {
    fontSize: '22px',
    lineHeight: '24px',
    textAlign: 'center',
    padding: '0 30px',
  },
  '@media(min-width: 768px) and (max-width: 769px)': {
    fontSize: '26px',
    lineHeight: '26px',
  },
});

const Content = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30px',
  textAlign: 'left',
  color: '#666666',
  marginTop: '20px',
  paddingRight: '16px',

  '@media (min-width: 768px) and (max-width: 769px)': {
    fontSize: '18px',
    lineHeight: '26px',
  },

  '@media (min-width: 769px) and (max-width: 912px)': {
    fontSize: '19px',
    lineHeight: '26px',
  },

  '@media (max-width: 767px)': {
    fontSize: '16px',
    lineHeight: '24px',
    padding: '0 20px',
    marginTop: '14px',
    textAlign: 'center',
  }
});

const Content1 = styled(Content)({
  '@media (min-width: 540px) and (max-width: 767px)': {
    paddingTop: '90px'
  }
})

const ImageContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: '40px',

  '@media(max-width: 767px)': {
    height: '350px',
  },
  '@media (min-width: 540px) and (max-width: 767px)': {
    height: '425px',
  }
});

const BackgroundImage = styled('img')({
  position: 'absolute',
  width: '90%',
  height: '90%',
  objectFit: 'cover',
  overflow: 'visible',
  // borderRadius: '16px',
});

const ForegroundImage = styled('img')({
  position: 'relative',
  width: '300px',
  height: 'auto',
  borderRadius: '16px',

  '@media (min-width: 768px) and (max-width: 819px)': {
    width: '200px',
  },
  '@media (min-width: 820px) and (max-width: 913px)': {
    width: '250px',
  },
  '@media(min-width: 913px) and (max-width: 1024px)': {
    width: '250px',
  },
  '@media(min-width: 1025px) and (max-width: 1280px)': {
    width: '250px',
  },
  '@media(max-width: 767px)': {
    width: '220px',
  },
  '@media (min-width: 540px) and (max-width: 541px)': {
    width: '320px',
  },
  '@media(max-width: 360px)': {
    width: '200px',
  }

});

const RevolutionText = styled(Typography)({
  fontFamily: 'Gloria Hallelujah',
  fontSize: '22px',
  fontWeight: 400,
  lineHeight: '35px',
  color: 'var(--Color-100, #014C35)',
  textAlign: 'center',
  paddingBottom: '8px',

  '@media (max-width: 767px)': {
    fontSize: '16px',
    color: '#0B6E4F',
    lineHeight: '19px',
  }
});

const HorizontalLine = styled(Box)({
  width: '100%',
  height: '1px',
  backgroundColor: '#B8B8B8',
  marginTop: '20px',
  marginBottom: '20px'
});

const CountText = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '70px',
  fontWeight: 700,
  lineHeight: '98.21px',
  textAlign: 'left',
  color: 'var(--Color-100, #014C35)'
});

const LoremText = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: '43.65px',
  textAlign: 'left',
  color: 'var(--Color-100, #014C35)',
});

const CornerImage = styled('img')({
  position: 'absolute',
  bottom: '-150px',
  left: '250px',
  width: '30%',
  height: 'auto',
  borderRadius: '8px',
  zIndex: 1,

  '@media(min-width: 768px) and (max-width: 819px)': {
    bottom: '-80px',
    left: '51px',
    width: '40%',
  },
  '@media(min-width: 820px) and (max-width: 913px)': {
    bottom: '-90px',
    left: '51px',
    width: '38%',
  },
  '@media(min-width: 913px) and (max-width: 1024px)': {
    bottom: '-100px',
    left: '140px',
    width: '33%',
  },
  '@media(min-width: 1025px) and (max-width: 1280px)': {
    bottom: '-112px',
    left: '180px',
    width: '30%',
  },
  '@media (max-width: 767px)': {
    bottom: '11px',
    left: '65px',
    width: '35%',
  },
  '@media (min-width: 540px) and (max-width: 541px)': {
    bottom: '-100px',
    left: '60px',
  }
});




const AboutUs = forwardRef((props, ref) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [count, setCount] = useState(0);

  useEffect(() => {
    const end = 23;
    const duration = 2000;
    const startTime = performance.now();

    const animateCount = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime/ duration, 1);
      const currentCount = Math.floor(progress * end);
      setCount(currentCount);

      if(progress < 1){
        requestAnimationFrame(animateCount);
      }
    };

    requestAnimationFrame(animateCount);
  }, []);

  return (
    <Box
      sx={{
        padding: {
          xs: "40px 0 40px",
          sm: "40px 0 100px"
        },
        background: {
          xs: "#F0FFFA",
          sm: "white",
        },
      }}
      className="aboutus"
      ref={ref}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <RevolutionText>Revolutionizing Packaged Food</RevolutionText>
          <SectionTitle>About Us</SectionTitle>
          <Separator src={SeperatorIcon} alt="Separator" />
        </Grid>
        <Grid item container xs={12} spacing={4}>
          {!isMobile && (
            <Grid item xs={12} sm={6} md={6} order={{ xs: 2, sm: 1 }}>
              <ImageContainer>
                {/* <BackgroundImage src={GreenImage} alt="Background Highlight" /> */}
                <ForegroundImage
                  src={ReactangleImg1}
                  alt="Cooking in Kitchen"
                  className='foregroundImage'
                />
                <CornerImage src={ReactangleImg} alt="Corner Decoration" />
              </ImageContainer>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={6} order={{ xs: 1, sm: 2 }}>
            <Heading>We make your food habits healthier!</Heading>
            <Content>
              At Frissly, we are pioneering a revolution against packaged and
              chemical-laden foods. Our mission is to reshape dietary habits by
              making pure, organic, and wholesome food conveniently accessible
              to everyone. We offer a diverse range of products, celebrating the
              rich culinary traditions of India.
            </Content>
            {isMobile && (
            <Grid item xs={12} sm={6} md={6} sx={{paddingBottom: {
              xs: '20px',
              sm: 'none',
            }}}>
              <ImageContainer>
                {/* <BackgroundImage src={GreenImage} alt="Background Highlight" /> */}
                <ForegroundImage
                  src={ReactangleImg1}
                  alt="Cooking in Kitchen"
                />
                <CornerImage src={ReactangleImg} alt="Corner Decoration" />
              </ImageContainer>
            </Grid>
          )}
            <Content1>
              We are using ingredients cherished in ancient Indian cuisine, such
              as whole grains like{" "}
              <b>
                Millets, Red Rice, Whole Wheat, Honey, Jaggery, Coconut Sugar,
                Palm Sugar
              </b>{" "}
              And More. We adhere to the highest standards of sustainability and
              transparency, ensuring that every bite of Frissly food not only
              nourishes the body but also nurtures our communities and the
              planet.
            </Content1>
            {/* <HorizontalLine /> */}
            {/* <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <CountText>{count}+</CountText>
              </Grid>
              <Grid item xs={12} md={9} container alignItems="center">
                <LoremText>Lorem ipsum.</LoremText>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default AboutUs;
