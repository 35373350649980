import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const HeaderContainer = styled(Box)({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 0',
  gap: '64px',
  opacity: '1',
//   backgroundColor: 'rgba(0, 0, 0, 0.07)',
});

const NavigationLink = styled(Link)({
  fontFamily: 'Montserrat',
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '21.94px',
  color: '#FFFFFF',
  cursor: 'pointer',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  }
});

const Header = () => {
  return (
    <HeaderContainer>
      <NavigationLink to="#why-frissly">Why Frissly?</NavigationLink>
      <NavigationLink to="#our-process">Our Process</NavigationLink>
      <NavigationLink to="#contact-us">Contact Us</NavigationLink>
    </HeaderContainer>
  );
};

export default Header;
