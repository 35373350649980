import React, { forwardRef } from 'react';
import { Box, Grid, Typography,useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import GreenImage from '../../../images/homepage/our_process/highlight.png';
import KitchenImage from '../../../images/homepage/our_process/kitchen.png';
import PopularProfessionImg from '../../../images/homepage/our_process/popular.png';
import SeperatorIcon from '../../../images/homepage/seperator.jpg'



const SectionTitle = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '38px',
  fontWeight: 700,
  lineHeight: '35px',
  color: '#3D3D3D',
  paddingBottom: '10px',
  textAlign: 'center',

  '@media (max-width: 767px)': {
    fontSize: '20px',
    lineHeight: '24.55px',
  }
});

const Separator = styled('img')`
  display: block;
  margin: 0 auto 20px; 
  padding-bottom: 30px;

  @media(max-width: 767px){
    margin: 0 auto 15px;
    padding-bottom: unset;
    width: 32%;
  }
`;

const RevolutionText = styled(Typography)({
  fontFamily: 'Gloria Hallelujah',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '35px',
  color: 'var(--Color-100, #014C35)',
  textAlign: 'center',
  paddingBottom: '8px',

  '@media (max-width: 767px)': {
    fontSize: '16px',
    color: '#0B6E4F',
    lineHeight: '19px',
  }
});

const Heading = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '28px',
  fontWeight: 700,
  lineHeight: '35px',
  textAlign: 'left',
  color: '#3D3D3D',

  '@media (max-width: 767px)': {
    fontSize: '22px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  '@media(min-width: 768px) and (max-width: 769px)': {
    fontSize: '26px',
    lineHeight: '26px',
  },

});

const Content = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30px',
  textAlign: 'left',
  color: '#666666',
  marginTop: '20px',

  '@media (min-width: 768px) and (max-width: 769px)': {
    fontSize: '18px',
    lineHeight: '26px',
  },

  '@media (min-width: 769px) and (max-width: 912px)': {
    fontSize: '19px',
    lineHeight: '26px',
  },

  '@media (max-width: 767px)': {
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '14px',
    textAlign: 'center',
  }
});

const Content1 = styled(Content)({
  '@media (max-width: 767px)': {
    marginTop: 'unset',
  }
})

const ImageContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '@media(max-width: 767px)': {
    width: '70%',
    margin: '0 auto',
  }
});

const BackgroundImage = styled('img')({
  position: 'absolute',
  width: '90%',
  height: '90%',
  objectFit: 'cover',
  overflow: 'visible',
  // borderRadius: '16px',
});

const ForegroundImage = styled('img')({
  position: 'relative',
  maxWidth: '100%',
  maxHeight: '100%',
  width: 'auto',
  height: 'auto',
  borderRadius: '16px'
});


const OurProcess = forwardRef((props, ref) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Box sx={{ py: '30px' }} ref={ref}>
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <Grid item xs={12}>
        <RevolutionText>A Journey of Freshness</RevolutionText>
        <SectionTitle>Our Process</SectionTitle>
        <Separator src={SeperatorIcon} alt="Separator" />
      </Grid>
      <Grid item container xs={10} spacing={4}>
        <Grid item xs={12} sm={6} md={6}>
          <Heading>24-hour Delivery Promise</Heading>
          <Content>
          We take pride in our meticulous process, ensuring that your food is as fresh and wholesome as possible. Our journey begins with sourcing the finest Organic and non-GMO ingredients. We manufacture our products in <b>small batches within 16 to 24 hours</b> of receiving your order, maintaining the highest standards of hygiene and quality. 
          </Content>
          {isMobile && (
          <ImageContainer sx={{padding: '30px 0'}}>
            {/* <BackgroundImage src={GreenImage} alt="Background Highlight" /> */}
            <ForegroundImage src={PopularProfessionImg} alt="Cooking in Kitchen" />
          </ImageContainer>
        )}
          <Content1>
          Our eco-friendly packaging further ensures that the food you receive is not only good for you but also for the environment. Finally, our commitment to a <b>24-hour delivery promise</b> guarantees that you get the freshest products delivered right to your doorstep.
          </Content1>
        </Grid>
        {!isMobile && (<Grid item xs={12} sm={6} md={6}  alignContent={'center'}>
          <ImageContainer>
            {/* <BackgroundImage src={GreenImage} alt="Background Highlight" /> */}
            <ForegroundImage src={PopularProfessionImg} alt="Cooking in Kitchen" />
          </ImageContainer>
        </Grid>)}
      </Grid>
    </Grid>
    </Box>
  );
});

export default OurProcess;
