import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { styled } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import LeafImg from "../../../images/homepage/launching_soon/Leaves.png";
import FirstImg from "../../../images/homepage/launching_soon/dosa.png";
import SecondImg from "../../../images/homepage/launching_soon/dish.png";
import PastaImg from "../../../images/homepage/launching_soon/pasta.png";
import BuscuitImg from "../../../images/homepage/launching_soon/buscuit.png";
import LadooImg from "../../../images/homepage/launching_soon/ladoo.png";
import ChocalateImg from "../../../images/homepage/launching_soon/chocalate.png";
import SeparatorIcon from "../../../images/homepage/launching_soon/seperator1.png";

const FixedBox = styled(Box)({
  position: "fixed",
  bottom: "0px",
  left: "50%",
  transform: "translateX(-50%)",
  background: "var(--Color-100, #014C35)",
  padding: "10px",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  zIndex: 1000,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "90px",
});

const StyledText1 = styled(Typography)({
  fontFamily: "Gloria Hallelujah",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "12px",
  color: "#F0FFFA",
  textAlign: "center",
  paddingBottom: "5px",
});

const StyledText2 = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "20px",
  color: "white",
  textAlign: "center",
  paddingBottom: "8px",
});

const Image = styled("img")({
  width: "70px",
  height: "70px",
  objectFit: "cover",
  margin: "0 10px",
  border: "1.5px solid white",
  borderRadius: "8px",
  display: "block",
  padding: "0px",
});

const TextContainer = styled(Box)({
  position: "relative",
  display: "inline-block",
});

const Separator = styled("img")({
  display: "block",
  margin: "0 auto",
  width: "32%",
});

const SwiperSlideContent = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });
  

const LaunchingSoonMobile = () => {
  return (
    <FixedBox>
      <Container>
        <Swiper
          spaceBetween={15}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 2000, disableOnInteraction: true }}
          modules={[Autoplay]}
          allowTouchMove={false}
        >
          <SwiperSlide>
            <Box sx={{ textAlign: "center" }}>
              <StyledText1>The wait is over</StyledText1>
              <TextContainer>
                <StyledText2>Launching Soon</StyledText2>
              </TextContainer>
              <Separator src={SeparatorIcon} alt="Separator" />
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <SwiperSlideContent>
              <Image src={FirstImg} alt="First Image" />
              <Image src={SecondImg} alt="Second Image" />
            </SwiperSlideContent>
          </SwiperSlide>
          <SwiperSlide>
            <Box sx={{ textAlign: "center" }}>
              <StyledText1>The wait is over</StyledText1>
              <TextContainer>
                <StyledText2>Launching Soon</StyledText2>
              </TextContainer>
              <Separator src={SeparatorIcon} alt="Separator" />
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <SwiperSlideContent>
              <Image src={PastaImg} alt="Pasta Image" />
              <Image src={BuscuitImg} alt="Buscuit Image" />
            </SwiperSlideContent>
          </SwiperSlide>
          <SwiperSlide>
            <Box sx={{ textAlign: "center" }}>
              <StyledText1>The wait is over</StyledText1>
              <TextContainer>
                <StyledText2>Launching Soon</StyledText2>
              </TextContainer>
              <Separator src={SeparatorIcon} alt="Separator" />
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <SwiperSlideContent>
              <Image src={LadooImg} alt="Ladoo Image" />
              <Image src={ChocalateImg} alt="Chocalate Image" />
            </SwiperSlideContent>
          </SwiperSlide>
        </Swiper>
      </Container>
    </FixedBox>
  );
};

export default LaunchingSoonMobile;
