import React, { forwardRef } from "react";
import IngredientsMobile from "./IngredientsMobile";
import IngredientsDesktop from "./IngredientsDesktop";
import { useMediaQuery } from "@mui/material";


const Ingredients = forwardRef((props, ref) => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <>
      {isMobile ? <IngredientsMobile /> : <IngredientsDesktop ref={ref} />}
    </>
  )

});

export default Ingredients;
