import React from 'react';
import { Grid, Box, CardMedia, CardContent, Typography, Container, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import photo1 from '../../../images/homepage/mask_group.png'
import photo2 from '../../../images/homepage/mask_group2.png'
import photo3 from '../../../images/homepage/mask_group3.png'
import photo4 from '../../../images/homepage/mask_group4.png'
import photo5 from '../../../images/homepage/mask_group5.png'
import photo10 from '../../../images/homepage/mask_group10.png'
import Sweet1 from '../../../images/homepage/categories/sweet1.png'
import Sweet2 from '../../../images/homepage/categories/chapati.png'
import Sweet3 from '../../../images/homepage/categories/chapati1.png'
import Sweet4 from '../../../images/homepage/categories/jam.png'
import Sweet5 from '../../../images/homepage/categories/cereal.png'
import Sweet6 from '../../../images/homepage/categories/icecreame1.png'
import Sweet7 from '../../../images/homepage/categories/chocalate.png'
import Sweet8 from '../../../images/homepage/categories/ladoo.png'
import Sweet9 from '../../../images/homepage/categories/sauce.png'
import Sweet10 from '../../../images/homepage/categories/maggie.png'
// import Sweet1 from '../../../images/homepage/categories/sweet1.png'
import SeparatorImg from '../../../images/homepage/seperator.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import 'swiper/css/pagination';
import './Categories.scss';


const ParentBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  height: '100vh',
});

const StyledBox = styled(Box)({
  width: '100%',
  height: 'auto',
  textAlign: 'center',
  position: 'relative',
});

const StyledImage = styled('img')({
  width: '75%',
  height: 'auto',
  objectFit: 'contain',
});

const Overlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

const Title = styled(Typography)({
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '35px',
    color: '#3D3D3D',

    '@media (max-width: 767px)': {
      fontSize: '22px',
      textAlign: 'center',
      marginTop: '8px'
    }

})

const SectionTitle = styled(Typography)({
    fontFamily: 'Nunito',
    fontSize: '38px',
    fontWeight: 700,
    lineHeight: '35px',
    color: '#3D3D3D',
    paddingBottom: "10px",
    textAlign:'center',

    '@media (max-width: 767px)': {
      fontSize: '20px',
      lineHeight: '24.55px',
  }
});

const Separator = styled('img')`
  display: block;
  margin: 0 auto 20px; 
  padding-bottom: 40px;

  @media(max-width: 767px){
    margin: 0 auto 15px;
    padding-bottom: 30px;
    width: 32%;
  }
`;

const RevolutionText = styled(Typography)({
  fontFamily: 'Gloria Hallelujah',
  fontSize: '22px',
  fontWeight: 400,
  lineHeight: '35px',
  color: 'var(--Color-100, #014C35)',
  textAlign: 'center',
  paddingBottom: '8px',

  '@media (max-width: 767px)': {
    fontSize: '16px',
    color: '#0B6E4F',
    lineHeight: '19px',
  }
});

const photos = [
  { id: 1, title: 'Cookies & Cakes', url: Sweet1 },
  { id: 2, title: 'Dosa & Idly Batters', url: Sweet2 },
  { id: 3, title: 'Chapati & Paratha', url: Sweet3 },
  { id: 4, title: 'Jam, Nut Butter & Spreads', url: Sweet4 },
  { id: 5, title: 'Cereals & Granola', url: Sweet5 },
  { id: 6, title: 'Ice cream & Popsicles', url: Sweet6 },
  { id: 7, title: 'Chocolate Bars & Truffles', url: Sweet7 },
  { id: 8, title: 'Snacks & Sweets', url: Sweet8 },
  { id: 9, title: 'Pickles & Powders', url: Sweet9 },
  { id: 10, title: 'Instant Breakfast', url: Sweet10 },
];

const Categories = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <Box sx={{padding: "40px 0 30px"}}>
    <Container>
        <RevolutionText>The wait is over</RevolutionText>
        <SectionTitle>Our Categories</SectionTitle>
        <Separator src={SeparatorImg}  alt="Separator" />
        {isMobile ? (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation
            loop={true}
            autoplay={{delay: 8000, disableOnInteraction: false}}
            modules={[Autoplay, Pagination, Navigation]}
            className='categories-swiper'
          >
            {photos.map((photo) => (
              <SwiperSlide key={photo.id}>
                <StyledBox>
                  <StyledImage src={photo.url} alt={photo.title} />
                </StyledBox>
                <Title variant="body2">
                  {photo.title}
                </Title>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (

      <Grid container spacing={2}>
        {photos.map((photo) => (
          <Grid item xs={6} sm={2.4} md={2.4} key={photo.id} sx={{py:1}}>
            <StyledBox>
              <StyledImage src={photo.url} alt={photo.title} />
              {/* <Overlay /> */}
            </StyledBox>
            <Title variant="body2"  style={{ textAlign: 'center', marginTop: '8px' }}>
              {photo.title}
            </Title>
          </Grid>
        ))}
      </Grid> )}
    </Container>
    </Box>
  );
};

export default Categories;
