import React from "react";
import { Container, Grid, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/system";
import WomenImg from "../../../images/homepage/women.jpg";
import { css } from "@mui/styled-engine";
import CookieImg from '../../../images/homepage/food_info/cookie.png'
import ChochlateImg from '../../../images/homepage/food_info/chochloate.png'
import IceCreameImg from '../../../images/homepage/food_info/icecreame.png'
import OrangeSliceImg from '../../../images/homepage/food_info/orangeSlice.png'

const StyledContainer = styled(Container)({
  paddingTop: "60px",

  '@media(max-width: 767px)': {
    paddingBottom: "40px"
  }
});

const StyledBox = styled(Box)(({ theme, backgroundColor }) => ({
  backgroundColor,
  padding: "16px",
  textAlign: "center",
  position: "relative",
  borderRadius: "24px",
  height: "80%",

  [theme.breakpoints.down("sm")]: {
    padding: "16px 28px",
    height: "unset",
    width: "100%",
  }
  
}));

const StyledBoxMiddle = styled(StyledBox)(({ backgroundColor }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

const PaddedTopBox = styled(StyledBox)({
  paddingTop: "60px",

  '@media (min-width: 768px) and (max-width: 820px)': {
    paddingTop: "30px"
  },
  '@media (min-width: 820px) and (max-width: 913px)': {
    paddingTop: "30px"
  },

});

const PaddedBottomBox = styled(StyledBox)({
  paddingBottom: "20px",
});

const StyledImage = styled("img")(({ isRounded }) => ({
  width: "100%",
  borderRadius: isRounded ? "50%" : "8px",
  boxShadow: "0px 9px 12px 0px #FFFFFF40",
}));

const StyledImage1 = styled("img")(({ isRounded }) => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  // borderRadius: isRounded ? "50%" : "8px",
}));

const SublineText = styled(Typography)(
  ({ index }) => css`
    font-family: "Nunito";
    font-size: 20px;
    font-weight: 500;
    line-height: 31.37px;
    color: #f6f5f5;

    @media (min-width: 768px) and (max-width: 854px){
      font-size: 16px;
      line-height: 26px;
    }

    @media(max-width: 767px){
      font-size: 17px;
    }

    ${index === 0 &&
    css`
      padding-top: 18px;
    `}
  `
);

const MainText = styled(Typography)(
  ({ index }) => css`
    font-family: "Nunito", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 35px;
    text-align: center;
    color: #ffffff;
    padding: 0 16px;

    @media (min-width: 768px) and (max-width: 769px){
      font-size: 20px;
      padding-bottom: unset;
      line-height: 26px;
    }
      
    @media (min-width: 852px) and (max-width: 854px){
      font-size: 20px;
      padding-bottom: unset;
    }

    @media (min-width: 820px) and (max-width: 913px){
      font-size: 20px;
      padding-bottom: unset;
    }
      
    @media (min-width: 913px) and (max-width: 1024px){
      padding-bottom: unset;
    }
    
    @media (max-width: 767px){
      font-size: 20px;
    }

    ${index === 0 &&
    css`
      padding-bottom: 18px;
    `}
  `
);

const EatBetterText = styled(Typography)(({ theme }) => ({
  fontFamily: "Nunito",
  fontSize: "21px",
  fontWeight: "700",
  lineHeight: "32.74px",
  letterSpacing: "0.03em",
  color: "#FFFFFF",
  paddingBottom: "10px",
  textAlign: "center",

  '@media (min-width: 768px) and (max-width: 820px)': {
    fontSize: "20px",
    lineHeight: "26px",
  },
  '@media (min-width: 820px) and (max-width: 913px)': {
    fontSize: '20px',
    paddingBottom: 'unset',
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "left",
  },
}));

// const DownloadButton = styled(Button)({
//   backgroundColor: '#FFFFFF',
//   color: '#000000',
//   boxShadow: '4px 4px 4px 0px #00000040',
//   padding: '10px 14px',
//   gap: '8px',
//   fontFamily: 'Nunito',
//   fontSize: '14px',
//   fontWeight: '500',
//   lineHeight: '21.82px',
//   borderRadius: '8px',
//   '&:hover': {
//     backgroundColor: '#f0f0f0',
//   },
// })({
//     disableElevation: true,
//     disableRipple: true,
// });

const DownloadButton = styled(Button)(
  ({ theme }) => css`
    background-color: #ffffff;
    color: #000000;
    box-shadow: 4px 4px 4px 0px #00000040;
    padding: 10px 14px;
    gap: 8px;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21.82px;
    border-radius: 8px;
    &:hover {
      background-color: #f0f0f0;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  `
);

const FullHeightGrid = styled(Grid)({
  height: {
    xs:"none",
    sm: "80%",
  }
});

const HalfHeightGridItem = styled(Grid)({
  height: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const FoodInfo = () => {

  return (
    <StyledContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} sx={{paddingTop: {xs: "16px!important", sm: "24px!important"}}} order={{ xs: 3, sm: 1 }}>
          <PaddedTopBox backgroundColor="#D97806">
            <SublineText variant="subtitle1">
              Indulge in Guilt-Free Goodness!
            </SublineText>
            <MainText index={0} variant="h6">
              Wholesome ingredients and zero Chemicals.
            </MainText>
            <Box sx={{ width: "100%", height: "50%" }}>
              <StyledImage1 src={CookieImg} alt="Cookies" />
            </Box>
          </PaddedTopBox>
        </Grid>
        <Grid item xs={12} sm={4} sx={{paddingTop: {xs: "16px!important", sm: "24px!important"}}} order={{ xs: 2, sm: 2 }}>
          <FullHeightGrid container spacing={2}>
            <HalfHeightGridItem item xs={12} order={{ xs: 2, sm: 1 }}>
              <StyledBoxMiddle backgroundColor="#3B5998">
                <Grid container spacing={2}>
                  <Grid item xs={5} sm={6} md={6}>
                    <StyledImage1 src={IceCreameImg} alt="Salad" isRounded />
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    sm={6}
                    md={6}
                    container
                    alignItems={"center"}
                  >
                    <EatBetterText variant="h6">
                      Delight in Every Scoop!
                    </EatBetterText>
                    {/* <DownloadButton variant="contained" disableRipple>
                      Download Now
                    </DownloadButton> */}
                  </Grid>
                </Grid>
              </StyledBoxMiddle>
            </HalfHeightGridItem>
            <HalfHeightGridItem item xs={12} sx={{ marginTop: {xs: "unset", sm: "10px"} }} order={{ xs: 1, sm: 2 }}>
              <StyledBoxMiddle backgroundColor="#f82727">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={7}
                    sm={6}
                    md={6}
                    container
                    alignItems={"center"}
                  >
                    <EatBetterText variant="h6">
                      Spread the Love!
                      <br />
                      70% Fruit Zero Sugar
                    </EatBetterText>

                    {/* <DownloadButton variant="contained">
                      Download Now
                    </DownloadButton> */}
                  </Grid>
                  <Grid item xs={5} sm={6} md={6}>
                    <StyledImage1
                      src={OrangeSliceImg}
                      alt="Cookies"
                      isRounded
                    />
                  </Grid>
                </Grid>
              </StyledBoxMiddle>
            </HalfHeightGridItem>
          </FullHeightGrid>
        </Grid>
        <Grid item xs={12} sm={4} order={{ xs: 1, sm: 3 }}>
          <PaddedBottomBox backgroundColor="#014C35">
            <Box
              sx={{
                width: {
                  xs: "75%",
                  sm: "100%",
                },
                height: "50%",
                margin: {
                  xs: "0 auto",
                  sm: "unset"
                }
              }}
            >
              <StyledImage1 src={ChochlateImg} alt="Salad" isRounded />
            </Box>
            {/* <SublineText index={0} variant="subtitle1">
              Subline goes here
            </SublineText> */}
            <MainText variant="h6">
              Sweetened naturally with honey, coconut sugar, dates, jaggery,
              and brown sugar.
            </MainText>
          </PaddedBottomBox>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default FoodInfo;
