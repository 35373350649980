import React from 'react';
import { useMediaQuery } from '@mui/material';
import LaunchingSoonMobile from './LaunchingSoonMobile';
import LaunchingSoonDesktop from './LaunchingSoonDesktop';

const LaunchingSoon = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <>
      {isMobile ? <LaunchingSoonMobile /> : <LaunchingSoonDesktop />}
    </>
  );
};

export default LaunchingSoon;
