import React from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import 'swiper/css';
import SeparatorImg from '../../../images/homepage/seperator.jpg'
import Image1 from '../../../images/homepage/sustainable_goals/image1.png'
import Image2 from '../../../images/homepage/sustainable_goals/image2.png'
import Image3 from '../../../images/homepage/sustainable_goals/image3.png'
import Image4 from '../../../images/homepage/sustainable_goals/image4.png'
import Image5 from '../../../images/homepage/sustainable_goals/image5.png'
import Image6 from '../../../images/homepage/sustainable_goals/image6.png'
import Image7 from '../../../images/homepage/sustainable_goals/image7.png'



const goals = [
  { label: "Zero Hunger", color: "#DDA83A", url:Image1 },
  { label: "Good Health & Well Being", color: "#4C9F38", url:Image2 },
  { label: "Gender Equality", color: "#FF3920", url:Image3 },
  { label: "Decent Work & Economic Growth", color: "#A21942", url:Image4 },
  { label: "Industry, Innovation & Infrastructure", color: "#FD6925", url:Image5 },
  { label: "Reduced Inequalities", color: "#E91E63", url:Image6 },
  { label: "Responsible Consumption & Production", color: "#BF8B2E", url:Image7 },
];

const GoalBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: "10px",
});

const GoalImageStyled = styled("img")({
  width: "50px",
  height: "50px",
  marginBottom: "10px",
});

const GoalLabel = styled(Typography)({
  fontSize: "14px",
  fontWeight: "bold",
  padding: "20px 0",
});

const SectionTitle = styled(Typography)({
  fontFamily: 'Nunito',
  fontSize: '38px',
  fontWeight: 700,
  lineHeight: '35px',
  color: '#3D3D3D',
  paddingBottom: "10px",
  textAlign:'center',

  '@media (max-width: 767px)': {
    fontSize: '20px',
    lineHeight: '24.55px',
}
});

const Separator = styled('img')`
  display: block;
  margin: 0 auto 20px; 
  padding-bottom: 40px;

  @media(max-width: 767px){
    margin: 0 auto 15px;
    padding-bottom: 30px;
    width: 32%;
  }
`;


const SustainableGoals = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <Box sx={{ padding: {xs: "40px 0 10px", sm: "50px 0", md: "60px 0", lg: "100px 0 80px"} }}>
      <Container>
        <SectionTitle>Sustainable Goals</SectionTitle>
        <Separator src={SeparatorImg}  alt="Separator" />
      <Swiper
        spaceBetween={15}
        slidesPerView={isMobile ? 2.5 : 5}
        loop={true}
        navigation={true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Autoplay, Navigation]}
        navigation={false}
      >
        {goals.map((goal, index) => (
          <SwiperSlide key={index}>
            <GoalBox>
              <GoalLabel sx={{color: goal?.color}}>{goal?.label}</GoalLabel>
              <GoalImageStyled src={goal?.url} alt={goal?.label}/>
            </GoalBox>
          </SwiperSlide>
        ))}
      </Swiper>
      </Container>
    </Box>
  );
};

export default SustainableGoals;
